import React, { useEffect } from 'react';
import { useSystemSetting } from '@fdha/common-hooks';
import {
  ChangePassword,
  CreateAccount,
  Login,
  ForgotPassword,
  ResetPassword,
} from '@pages';
import { useLocalStorage } from '@hooks';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

export const publicPageNameByRoute = [
  { route: '/', name: 'Login', i18nKey: 'login:pageTitle' },
  {
    route: '/change-password',
    name: 'Change Password',
    i18nKey: 'login:changePassword.title',
  },
  {
    route: '/create-account',
    name: 'Create Account',
    i18nKey: 'login:createAccount.pageTitle',
  },
  {
    route: '/forgot-password',
    name: 'Forgot Password',
    i18nKey: 'login:forgotPassword.pageTitle',
  },
  {
    route: '/reset-password',
    name: 'Reset Password',
    i18nKey: 'login:forgotPassword.title',
  },
];

export const Public = () => {
  const { value: isSignupEnabled } =
    useSystemSetting<boolean>('isSignupEnabled');
  const location = useLocation();
  const { saveToLocalStorage } = useLocalStorage('redirectUrl', false);

  useEffect(() => {
    const currentUrl = location.pathname + location.search;
    if (!publicPageNameByRoute.some((page) => page.route === currentUrl)) {
      saveToLocalStorage({ url: currentUrl });
    }
  }, [location, saveToLocalStorage]);

  return (
    <Routes>
      <Route element={<ScrollToTop />}>
        <Route path="/change-password" element={<ChangePassword />} />
        {isSignupEnabled && (
          <Route path="/create-account" element={<CreateAccount />} />
        )}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};
